<template>
  <div class="py-10 max-w-7xl mx-auto sm:px-6 lg:px-8">
    <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">Add Kairo to your calendar</h1>
  </div>
  <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
    <p>
      Create a recurring event in your calendar, whenever you start your day, let's say at 8 AM.
    </p>
    <p class="mt-4">
      To make sure you remember to use the Kairo app and take a moment to focus on yourself and your well-being, add a daily event to your calendar as a reminder to start your day with Kairo. This will help you make it a habit and consistently prioritize your well-being.
    </p>
    <p class="mt-4">
      You can also set Kairo as your default homepage in your browser. Here are instructions if you are using <a href="https://support.google.com/chrome/answer/95314?hl=en&co=GENIE.Platform%3DDesktop#zippy=%2Chave-a-new-tab-open" target="_blank" class="text-green-600 dark:text-green-500 hover:underline">Chrome</a>.
    </p>
    <button v-show="!added" @click="doneThis" type="button"
      class="mt-4 border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50"
      style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
    >
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block" viewBox="0 0 20 20" fill="currentColor">
        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
      </svg>
      <span class="font-bold ml-1">I have done this</span>
    </button>
  </div>
  <div v-show="added" class="max-w-7xl mx-auto sm:px-6 lg:px-8 mt-4">
    <div class="rounded-md bg-green-50 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <!-- Heroicon name: mini/check-circle -->
          <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
          </svg>
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-green-800">Awesome!</h3>
          <div class="mt-2 text-sm text-green-700">
            <p>You have added a recurring event to your calendar. Now you can start your day with Kairo and focus on your well-being.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  //outlook, office365, yahoo, 
  //import { google } from "calendar-link";

  export default {
    name: "AddCalendar",
    data() {
      return {
        added: false,
      };
    },
    computed: {
    },
    methods: {
      doneThis() {
        window.EngageKit.track('Recurring Event Added');
        this.added = true;
      },
      /*
      addToCalendar() {
        const event = {
          title: "My birthday party",
          description: "Be there!",
          start: "2019-12-29 18:00:00 +0100",
          duration: [3, "hour"],
        };
        console.log(google(event));
      }
      */
    }
  };
</script>
